import ActionType from './type';


export const updateUserInfo = (userInfo) => ({
    type: ActionType.UPDATE_USER_INFO,
    userInfo
})

export const updateOperation = (operateState) => ({
    type: ActionType.UPDATE_USER_OPERATION,
    operateState
})

export const updateCovertType = (convertType) => ({
    type: ActionType.UPDATE_CONVERT_TYPE,
    convertType
})

export const updateLocale = (currentLocale) => ({
    type: ActionType.UPDATE_LOCALE,
    currentLocale
})


export const updateLastPage = (lastPage) => ({
    type: ActionType.UPDATE_LAST_PAGE,
    lastPage
})
export const updateClickedMenus = (item) => ({
    type: ActionType.UPDATE_CLICKED_MENUE,
    item
})

export const setCollapsed = (isCollapsed) => ({
    type: ActionType.UPDATE_COLLAPSED,
    isCollapsed
})
export const setLeftCollapsed = (isLeftCollapsed) => ({
    type: ActionType.UPDATE_LEFT_COLLAPSED,
    isLeftCollapsed
})
export const hiddenHorizontalMenuView = (isHidden) => ({
    type: ActionType.HIDDEN_HORIZON_MEMUE,
    isHidden
})

export const updateHeaderbarToggle = (isChangeToggle) => (
    {
        type: ActionType.UPDATE_HEADERBAR_TOGGLE,
        isChangeToggle
    }
)
export const openLeftFloatMenue = (isOpenLeftFloatMenue) => (
    {
        type: ActionType.OPEN_LEFT_FOLAT_MENUE,
        isOpenLeftFloatMenue
    }
)

export const changeLayOutType = (layoutType) => ({
    type: ActionType.CHANGE_LAYOUT_TYPE,
    layoutType
})

export const changeUserEnd = (isMobilephoneEnd) => ({
    type: ActionType.CHANGE_USEREND,
    isMobilephoneEnd
})

export const windowUpScroller = (isWindowUpScroll) => ({
    type: ActionType.WINDOW_UP_SCROLL,
    // type,
    isWindowUpScroll
})
export const updateUserEnd = (userEndSize) => ({
    type: ActionType.UPDATA_USER_END_SIZE,
    userEndSize
})
export const updateStoryCardNumber = (cardNumber) => ({
    type: ActionType.UPDATE_STORY_CARD_NUMBER,
    cardNumber
})

export const isHiddenCommentAStory = (isHidden) => ({
    type: ActionType.IS_HIDDEN_COMMENT_STORY,
    isHidden
})
export const updateOperateRelatedId = ({ did, sid }) => ({
    type: ActionType.UPDATE_SID,
    OperateRelatedId: { did, sid }
})
export const shareStory = (isShareStory) => ({
    type: ActionType.SHARE_STORY,
    isShareStory
})
export const loadingGeneratingView = (isShowGenerate) => ({
    type: ActionType.IS_GENERATE_STORY,
    isShowGenerate
})

export const toNewPage = (newPage) => ({
    type: ActionType.TO_NEW_PAGE,
    newPage
})
export const isClickedLogout = (isLogOut) => ({
    type: ActionType.IS_CLICKED_LOGOUT,
    isLogOut
})
export const leaveCreatePage = (isLeaving) => ({
    type: ActionType.LEAVE_CREATE_PAGE,
    isLeaving
})
export const interruptGenerateProcess = (isInterruptProcess) => ({
    type: ActionType.IS_INTERRUPT_PROCESS,
    isInterruptProcess
})
export const changeFactsheetLayoutType = (factsheetLayoutType) =>({
    type:ActionType.CHANGE_FACTSHEET_LAYOUT_TYPE,
    factsheetLayoutType
})